import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../shared/user.service';
import { Customer } from '../shared/customer.model';


@Component({
  selector: 'pm-customer-dispalay-self',
  templateUrl: './customer-dispalay-self.component.html',
  styleUrls: ['./customer-dispalay-self.component.css']
})
export class CustomerDispalaySelfComponent implements OnInit {

  customer: Customer;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  
  constructor(private userService: UserService, private toastr: ToastrService, public translate: TranslateService) { }


  ngOnInit() {
    this.resetForm();
    this.userService.getMyProfile('custid').subscribe((customer_id: any) => {
      
      this.userService.getCustomer(customer_id).subscribe((data: any) => {
        this.resetForm();
        this.customer = {
  
          customer_key: data.customer_key,
          customer_name: data.customer_name,
          customer_tax: data.customer_tax,
          customer_email: data.customer_email,
          customer_phone: data.customer_phone,
          customer_address: data.customer_address,
          customer_city: data.customer_city,
          customer_postal_code: data.customer_postal_code,
          customer_country: data.customer_country,
          anchor_crm: data.anchor_crm,
        }
      });

    });
  }

  resetForm(form?: NgForm) {

    if (form != null)

      form.reset();

    this.customer = {
      customer_key: 0,
      customer_name: 'NULL',
      customer_tax: 'NULL',
      customer_email: 'NULL',
      customer_phone: 'NULL',
      customer_address: 'NULL',
      customer_city: 'NULL',
      customer_postal_code: 'NULL',
      customer_country: 'NULL',
      anchor_crm: 'NULL',
    }
  }

  OnSubmit(form: NgForm) 
  {
    this.userService.getMyProfile('customerId').subscribe((userdata: any) => {

       this.userService.updateCustomer(form.value, userdata)
                 .subscribe((data: any) => {
                  this.toastr.success(this.GetTranslationForSuccessProfileUpdate());
        }, (err) => this.toastr.error(this.GetTranslationForErrorProfileUpdate()));
    });
  }

  GetTranslationForErrorProfileUpdate(): string {
     // CODEFAILUREPROFILEERROR
    var languageUsed = this.getSavedLanguage();

    if(languageUsed == "en")
    {
      return "Error saving data";
    }

    if(languageUsed == "pl")
    {
      return "Nastapil blad przy zapisywaniu zmian";
    }
  }

  GetTranslationForSuccessProfileUpdate(): string {
      // CODESUCCESFULPROFILEUPDATE
      var languageUsed = this.getSavedLanguage();

      if(languageUsed == "en")
      {
        return "Changes Saved";
      }
  
      if(languageUsed == "pl")
      {
        return "Zmiany zostaly zapisane";
      }
  }

  public getSavedLanguage(): string
  {
    const browserLang = this.translate.getBrowserLang();
    return browserLang.match(/en|pl/) ? browserLang : 'pl';
  }

}
