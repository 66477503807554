
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Application } from '../shared/application.model';
import { UserService } from '../shared/user.service';
import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pm-application-telerik-adm',
  templateUrl: './application-telerik-adm.component.html',
  styleUrls: ['./application-telerik-adm.component.css']
})

export class ApplicationTelerikAdmComponent implements OnInit{
    errors: any;
    isunique: any;
    shouldCheck: boolean
    tempAppCode: any;
    ngOnInit() {

            this.userService.getApplicationStatuses().subscribe((data: any) => {       
            this.applicationStatuses= data; 
        });

            this.userService.getApplications().subscribe((data: any) => {   
            this.applications = data;      
        });
}   
    public applications: any[];
    public applicationStatuses: any[];
    public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();
    public itemToRemove: any;

    constructor(private userService: UserService, private toastr: ToastrService, public translate: TranslateService, private formBuilder: FormBuilder) {
        this.createFormGroup = this.createFormGroup.bind(this);
        this.removeConfirmation = this.removeConfirmation.bind(this);
    }

    public createFormGroup(args: any): any {
        const item = args.isNew ? new Application() : args.dataItem;

        const formGroup = this.formBuilder.group({
            'application_key': item.application_key,
            'application_name': new FormControl(item.application_name, Validators.required),
            'application_code': new FormControl(item.application_code, Validators.required),
            'application_status': new FormControl(item.application_status, Validators.required),
             acceptTerms: [true, Validators.requiredTrue]
             }, {
                validator: this.AppCodeMustBeUnique('application_code')
           
        });

        // console.log("formGroup.valid: " + formGroup.valid);
        // console.log("formGroup.invalid: " + formGroup.invalid);
        // console.log("formGroup.pristine: " + formGroup.pristine);
        return formGroup;
    }
    
    public applicationStatuse(id: number): any {
        return this.applicationStatuses.find(x => x.application_status_key === id);
    }

    public CanAddNewApplications(): any
    {
        //Add logic here 
        return false;
    }

    public CanDeactivateApplications(): any
    {
        //Add logic here 
        return false;
    }

    public AppCodeMustBeUnique(controlName: string) 
    {
        return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        if (control.value == '' || control.value == undefined) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        if(this.tempAppCode == '' || this.tempAppCode == undefined)
        {
            this.shouldCheck = true;
            this.tempAppCode = control.value;
        }

        if(this.tempAppCode != control.value)
        {
                this.userService.isApplicationCodeUnique(control.value).subscribe((data: any) => {       
                this.isunique= data; 
                this.tempAppCode = control.value; 

                 // set error on matchingControl if validation fails
                    if (!this.isunique) {
                        control.setErrors({ mustMatch: true });
                    } else {
                        control.setErrors(null);
                    }

            });
        }
        else if(this.shouldCheck)
        {
            this.shouldCheck = false;
            this.userService.isApplicationCodeUnique(control.value).subscribe((data: any) => {       
            this.isunique= data;
            this.tempAppCode = control.value; 

                // set error on matchingControl if validation fails
                if (!this.isunique) {
                    control.setErrors({ mustMatch: true });
                } else {
                    control.setErrors(null);
                }
            });
        }
      }
    }

    public saveHandler({ sender, formGroup, rowIndex }) {
        this.errors = '';
        if (formGroup.valid)
        {
            this.userService.addApplication(formGroup.value)
                .subscribe(
                    result => {
                      // Handle result
                      this.toastr.success(this.GetTranslationForSuccessApplicationAdded());
                    },
                    error => {

                        let validationErrorDictionary = error.error.ModelState;
                        for (var fieldName in validationErrorDictionary) {
                            if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                            this.errors = (validationErrorDictionary[fieldName][0]);
                            }
                        }

                      this.toastr.error(this.errors);
                    }
                
            );
            sender.closeRow(rowIndex);
            //formGroup.value.application_code = '';
            formGroup.controls.application_code.value = '';
            this.tempAppCode = '';
        }
        else
        {
            formGroup.reset();
        }
    }

    public editHandler({sender, rowIndex, dataItem})
    {
        // this.closeEditor(sender);

        // this.editedRowIndex = rowIndex;
        // this.editedProduct = Object.assign({}, dataItem);

        sender.editRow(rowIndex);
    }


    GetTranslationForSuccessApplicationAdded(){
    // CODESUCCESAPPLICATIONADDED
        var languageUsed = this.getSavedLanguage();
       
        if(languageUsed == "en")
        {
          return "Application added";
        }
       
        if(languageUsed == "pl")
        {
          return "Aplikacja dodana";
        }   
    }

    public getSavedLanguage(): string
    {
      const browserLang = this.translate.getBrowserLang();
      return browserLang.match(/en|pl/) ? browserLang : 'pl';
    }

    public confirmRemove(shouldRemove: boolean): void {
        this.removeConfirmationSubject.next(shouldRemove);
        if (shouldRemove== true) {  
        this.userService.deactivateApplication(this.itemToRemove.application_key)
        .subscribe((data: any) => {
        });
        this.tempAppCode == '';
    }
            
        this.itemToRemove = null;
    }

    public removeConfirmation(dataItem): Subject<boolean> {
        this.itemToRemove = dataItem;
        return this.removeConfirmationSubject;
    }
}
