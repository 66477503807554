import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'pm-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent {

  userMenuItemList: any;
  userMenuItemListTmp: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private userService: UserService, public translate: TranslateService, private cookie: CookieService) {}
 
   loginStatus$ : Observable<boolean>;
   UserName$ : Observable<string>;

  ngOnInit() {
    
    this.userService.getUserMenuAccess().subscribe((data: any) => {
      this.userMenuItemList = data;
      //Save menu items in local storage
       localStorage.setItem('userMn',JSON.stringify(this.userMenuItemList));
      //this.cookie.set("userMn",JSON.stringify(this.userMenuItemList)); 
    });

   this.loginStatus$ = this.userService.isLoggedIn;
   //console.log("status : " + this.userService.isLoggedIn);
   this.UserName$ = this.userService.currentUserName;
   //console.log("username : " + this.userService.currentUserName);

  }

  Logout() {

    
    //this.cookie.delete('userToken');	
    //this.cookie.delete('Tokens');
    this.cookie.delete('acc_token');
    this.cookie.delete('mps_token');
    this.cookie.delete('userName');
    this.cookie.set('loginStatus','0');
    this.cookie.delete('userMn');

    // localStorage.removeItem('userToken');	
	  // localStorage.removeItem('Tokens');
    // localStorage.removeItem('userName');
    // localStorage.setItem('loginStatus','0');
    // // localStorage.removeItem('userMn');
    this.router.navigate(['/login']);
  }

//   ShowCategoryMenuItem(menuitem){
//     return menuitem.view_item;
//  }
 
//  ShowSubCategoryMenuItem(menuitem){
//   return menuitem.view_item;
// }

  GetMenuTranslated(menuitem){

    var languageUsed = this.getSavedLanguage();

    if(languageUsed == "pl" && menuitem == "Licenses")
    {
      return "Licencje";
    }

    if(languageUsed == "pl" && menuitem == "Applications")
    {
      return "Aplikacje";
    }

    if(languageUsed == "pl" && menuitem == "Users")
    {
      return "Uzytkownicy";
    }

    if(languageUsed == "pl" && menuitem == "User Profile")
    {
      return "Profil Uzytkownika";
    }

    if(languageUsed == "pl" && menuitem == "Customer Profile")
    {
      return "Profil Klienta";
    }

    if(languageUsed == "pl" && menuitem == "Manage Licenses")
    {
      return "Zarzadzaj Licencjami";
    }

     return menuitem;
  }


  public getSavedLanguage(): string
  {
    const browserLang = this.translate.getBrowserLang();
    return browserLang.match(/en|pl/) ? browserLang : 'pl';
  }

}


