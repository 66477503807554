import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { NgForm } from '@angular/forms';

@Component({
  selector: 'pm-provide-email-for-password-change',
  templateUrl: './provide-email-for-password-change.component.html',
  styleUrls: ['./provide-email-for-password-change.component.css']
})
export class ProvideEmailForPasswordChangeComponent implements OnInit {

  constructor(private userService : UserService, private router : Router, public translate: TranslateService) { }

  ngOnInit() {
  }

  OnSubmit(form: NgForm, userName: string)
  {
    form.reset();
    userName = userName.replace(/\s/g, "");
    var language = this.getSavedLanguage();
    this.userService.requestPasswordChange(userName, language).subscribe((data : any)=>{
    this.router.navigate(['/confirmPassword']);
   },

   (err : HttpErrorResponse)=>{
    this.router.navigate(['/confirmPassword']);
   });
 }

 public getSavedLanguage(): string
 {
   const browserLang = this.translate.getBrowserLang();
   return browserLang.match(/en|pl/) ? browserLang : 'pl';
 }

}
