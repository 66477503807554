import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { User } from './user.model';
import { BehaviorSubject } from 'rxjs';
import { Application } from './application.model';
import { Customer } from './customer.model';
import { LicensePermission } from '../manage-license/LicensePermissionModel';
import { ChangePasswordBindingModel } from '../user-display-self/ChangePasswordBindingModel';
import { ChangePasswordBindingModelExt } from './password.change.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable()

export class UserService
{
  readonly rootUrl = 'https://bim4traffic.com';

  //readonly rootUrl = 'http://localhost:52567';

  private loginStatus = new BehaviorSubject<boolean>(this.checkLoginStatus());
  // private UserName = new BehaviorSubject<string>(localStorage.getItem('userName'));
  private UserName = new BehaviorSubject<string>(this.cookie.get('userName'));

  constructor(private http: HttpClient, private cookie: CookieService) { }

  getCurrentToken() : string
  {
    let app_tokens = [];
    let current_token;
    // app_tokens = JSON.parse(this.cookie.get('Tokens').toString());
    // app_tokens.forEach(function (value)
    // {
    //   const app_type = value.substring(0, 4);
    //   if(app_type == 'acc:')
    //   {
    //     current_token = value.slice(4);
    //   }
    // }); 

    return this.cookie.get('mps_token'); //current_token;
  }

  registerUser(user: User) {

    const body: User = {

      first_name: user.first_name,

      last_name: user.last_name,

      email: user.email.replace(/\s/g, ""),

      phone_number: user.phone_number,

      address: user.address,

      city: user.city,

      postal_code: user.postal_code,

      country: user.country,

      password: user.password

    }
    
    var reqHeader = new HttpHeaders({'No-Auth':'True'});
    return this.http.post(this.rootUrl + '/users', body,{headers : reqHeader});
  }



  userAuthentication(userName, password) {
    this.loginStatus.next(true);
    this.UserName.next(userName);
    var data = "username=" + userName + "&password=" + password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.http.post(this.rootUrl + '/token', data, { headers: reqHeader });
  }

  // userAuthenticationMaps(userName, password) 
  // {
  //   const rootUrl2 = 'https://bim4traffic.com';
  //   this.loginStatus.next(true);
  //   this.UserName.next(userName);
  //   var data = "username=" + userName + "&password=" + password + "&grant_type=password";
  //   var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
  //   return this.http.post(rootUrl2 + '/token', data, { headers: reqHeader });
  // }


  getUserMenuAccess(){

   return  this.http.get(this.rootUrl+'/menuPerUser/0');
  }

  getUserAccess(){

    return  this.http.get(this.rootUrl+'/authPerUser/0');
   }

  getLicenses(){

    return  this.http.get(this.rootUrl+'/licenses');

   }

   getCustomers(){

    return  this.http.get(this.rootUrl+'/customers');
   }

   getApplications(){

    return  this.http.get(this.rootUrl+'/applications').map(
      (response) => {return response }
     );
 
   }


   getApplicationStatuses(){

    return  this.http.get(this.rootUrl+'/applicationStatuses').map(
      (response) => {return response }
      );
 
    }
 
    getUsers(){

      return  this.http.get(this.rootUrl+'/users').map(
        (response) => {return response }
       );
   
     }

    isApplicationCodeUnique(app_code){

      return  this.http.get(this.rootUrl+'/isApplicationCodeUnique/' + app_code).map(
        (response) => {return response }
        );
   
    }

  checkLoginStatus(): boolean
  {
    // var logged = localStorage.getItem('loginStatus');
    var logged = this.cookie.get('loginStatus');

     if(logged == "1")
     {
        return true;
     }
       return false;
  }

  get isLoggedIn()
  {
    return this.loginStatus.asObservable();
  }

  get currentUserName()
  {
    return this.UserName.asObservable();
  }

  addApplication(application: Application) {

    const body: Application = {

      application_key: application.application_key,
              
      application_name: application.application_name,

      application_code: application.application_code,

      application_status: application.application_status

    }

    const options = {
      // headers: new HttpHeaders().append("Authorization", "Bearer " + localStorage.getItem('userToken'))
      headers: new HttpHeaders().append("Authorization", "Bearer " + this.getCurrentToken())
    }

    return this.http.post(this.rootUrl + '/applications', body, options);
  }

  deactivateApplication(id: number) {

    const options = {
      // headers: new HttpHeaders().append("Authorization", "Bearer " + localStorage.getItem('userToken'))
      headers: new HttpHeaders().append("Authorization", "Bearer " + this.getCurrentToken())
    }

    return this.http.delete(this.rootUrl + '/applications/' + id, options);
  }

  // getUserProfile(email){
    
  //   return  this.http.get(this.rootUrl+'/users/' + email).map(
  //     (response) => {return response }
  //     );
  //  }


  getLoggedInUser(){

    return  this.http.get(this.rootUrl+'/user').map(
      (response) => {return response }
     );

   }

   updateUserProfile(user: User, email: String) {

    const body: User = {

      first_name: user.first_name,

      last_name: user.last_name,

      email: user.email,

      phone_number: user.phone_number,

      address: user.address,

      city: user.city,

      postal_code: user.postal_code,

      country: user.country,

      password: user.password

    }

    const options = {
      // headers: new HttpHeaders().append("Authorization", "Bearer " + localStorage.getItem('userToken'))
        headers: new HttpHeaders().append("Authorization", "Bearer " + this.getCurrentToken())
    }

    return this.http.put(this.rootUrl + '/users/' + email, body, options);
   }

   getMyProfile(code){
    return  this.http.get(this.rootUrl+'/myInfo/' + code).map(
      (response) => {return response }
      );
   }

   getCustomer(id){

    return  this.http.get(this.rootUrl+'/customers/' + id).map(
      (response) => {return response }
      );
 
  }

  updateCustomer(customer: Customer, id: number) {

    const body: Customer = {

      customer_key: customer.customer_key,
      customer_name: customer.customer_name,
      customer_tax: customer.customer_tax,
      customer_email: customer.customer_email,
      customer_phone: customer.customer_phone,
      customer_address: customer.customer_address,
      customer_city: customer.customer_city,
      customer_postal_code: customer.customer_postal_code,
      customer_country: customer.customer_country,
      anchor_crm: customer.anchor_crm

    }

    const options = {
      // headers: new HttpHeaders().append("Authorization", "Bearer " + localStorage.getItem('userToken'))
        headers: new HttpHeaders().append("Authorization", "Bearer " + this.getCurrentToken())
    }

    return this.http.put(this.rootUrl + '/customers/' + id, body, options);
   }

   getLicensePermissions(){

    return  this.http.get(this.rootUrl+'/licensePermissions').map(
      (response) => {return response }
     );
   }

   deactivateLicensePermission(id: number) {
 
    const options = {
      // headers: new HttpHeaders().append("Authorization", "Bearer " + localStorage.getItem('userToken'))
        headers: new HttpHeaders().append("Authorization", "Bearer " + this.getCurrentToken())
    }

    return this.http.delete(this.rootUrl + '/licensePermissions/' + id, options);
  }

  addLicensePermission(license_permission: LicensePermission) 
  {
    const body: LicensePermission = {

      license_key: license_permission.license_key,
              
      user_key: '',

      license_permission_status: 2,

      license_permission_expiration_date: '',

      license_permission_expiration_date_as_string: license_permission.license_permission_expiration_date_as_string,

      license_permission_key: 0,

      license_number: '',

      application_name: '',

      application_code: '',

      user_email: license_permission.user_email,

      name_of_user: ''

    }

    const options = {
      // headers: new HttpHeaders().append("Authorization", "Bearer " + localStorage.getItem('userToken'))
      headers: new HttpHeaders().append("Authorization", "Bearer " + this.getCurrentToken())
    }
    
    return this.http.post(this.rootUrl + '/licensePermissions', body, options);
  }

  getEmailAddresses(value : string)
  {
    return  this.http.get(this.rootUrl+'/emails/' + value).map(
      (response) => {return response }
     );
   }

   getLicensesForCustomersOnly()
   {
    return  this.http.get(this.rootUrl+'/licensesforcustomers');
   }

   changePasswordInternal(changePasswordBindingModel: ChangePasswordBindingModel) 
   {
     const body: ChangePasswordBindingModel = {
 
      OldPassword: changePasswordBindingModel.OldPassword,
               
      NewPassword: changePasswordBindingModel.NewPassword,
 
      ConfirmPassword: changePasswordBindingModel.ConfirmPassword

     }
 
     const options = {
      //  headers: new HttpHeaders().append("Authorization", "Bearer " + localStorage.getItem('userToken'))
        headers: new HttpHeaders().append("Authorization", "Bearer " + this.getCurrentToken())
     }
     
     return this.http.post(this.rootUrl + '/ChangePassword', body, options);
   }


   requestPasswordChange(email: string, language: string)
   {
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.http.get(this.rootUrl + '/InitiateExternalPasswordChange/' + email + '/' + language, { headers: reqHeader });
  }

  IsPasswordChangeCodeValid(email: string, code: string)
  {
   var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
   return this.http.get(this.rootUrl + '/IsPasswordChangeCodeValid/' + email + '/' + code, { headers: reqHeader });
 }

 ChangePasswordExternal(UserEmail: string, Code: string, 
  Language: string, NewPassword: string, ConfirmPassword: string)
 {

    const body: ChangePasswordBindingModelExt = {

      UserEmail: UserEmail,

      Code: Code,

      Language: Language,

      NewPassword: NewPassword,

      ConfirmPassword: ConfirmPassword

    }
    
    var reqHeader = new HttpHeaders({'No-Auth':'True'});
    return this.http.post(this.rootUrl + '/ChangePasswordExt', body,{headers : reqHeader});
  }

  GetActiveActivationsPerLicenseAndUser(license_number: string, for_user: string)
  {
    return  this.http.get(this.rootUrl + '/activeactivationsperuser/' + license_number + '/' + for_user);
  }
}