import { Component, OnInit } from '@angular/core';

import { UserService } from '../../shared/user.service';

import { HttpErrorResponse } from '@angular/common/http';

import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';



@Component({

  selector: 'app-sign-in',

  templateUrl: './sign-in.component.html',

  styleUrls: ['./sign-in.component.css']

})

export class SignInComponent implements OnInit {

  isLoginError : boolean = false;


  constructor(private userService : UserService,private router : Router, private cookie: CookieService) { }


  ngOnInit() {

  }



  // OnSubmit(userName,password){

  //    userName = userName.replace(/\s/g, "");
  //    this.userService.userAuthentication(userName,password).subscribe((data : any)=>{
  //     localStorage.setItem('userToken',data.access_token);
  //     localStorage.setItem('userName',userName);
  //     localStorage.setItem('loginStatus','1');
  //     this.router.navigate(['/profile']);
  //   },

  //   (err : HttpErrorResponse)=>{

  //     this.isLoginError = true;

  //   });
  // }

  OnSubmit(userName,password){

    userName = userName.replace(/\s/g, "");
    this.userService.userAuthentication(userName,password).subscribe((data : any)=>{
    //  localStorage.setItem('userToken',data.access_token);
    //  localStorage.setItem('userName',userName);
    //  localStorage.setItem('loginStatus','1');

    //this.cookie.set('test_cookie', data.access_token, 100, '/', '.gacad.pl');

     //this.cookie.set("userToken", data.access_token);
    //  this.cookie.set("userName", userName);
    //  this.cookie.set("loginStatus", "1");
    this.cookie.set("userName", userName, 100, '/', '.gacad.pl');
    this.cookie.set("loginStatus", "1", 100, '/', '.gacad.pl');
     //this.collectTokens('acc', data.access_token);
     this.cookie.set('mps_token', data.access_token, 100, '/', '.gacad.pl');
     this.router.navigate(['/profile']);

          // //Call another tk
          // this.userService.userAuthenticationMaps(userName,password).subscribe((mapsdata : any)=>{
          //   //this.collectTokens('mps', mapsdata.access_token);
          //   this.cookie.set('mps_token', mapsdata.access_token, 100, '/', '.gacad.pl');
          //   },
          //   (err : HttpErrorResponse)=>{
          // });          
   },

   (err : HttpErrorResponse)=>{

     this.isLoginError = true;

   });
 }

//  collectTokens(app, token)
//  {
//     let tokens = [];
//     if(this.cookie.get('Tokens'))
//     {
//       tokens = JSON.parse(this.cookie.get('Tokens').toString());
//       tokens = [app + ':' + token, ...tokens];
//     }else
//     {
//       tokens = [app + ':' + token];
//     }
//     this.cookie.set('Tokens',JSON.stringify(tokens));
//  }
// collectTokens(app, token)
// {
//    let tokens = [];
//    if(this.cookie.get('Tokens'))
//    {
//      tokens = JSON.parse(this.cookie.get('Tokens').toString());
//     //  tokens = [app + ':' + token , 10000000, '/myapp/', 'localhost', ...tokens];
//      tokens = [app + ':' + token, ...tokens];
//    }else
//    {
//      tokens = [app + ':' + token];
//    }

//    this.cookie.set('Tokens',JSON.stringify(tokens));
//    //this.cookie.set('Tokens',JSON.stringify(tokens), 10000000, '/myapp/', 'mydomain.com');
// }

//  collectTokens(app, token)
//  {
//     let tokens = [];
//     if(this.cookie.get('Tokens'))
//     {
//       tokens = JSON.parse(this.cookie.get('Tokens').toString());
//       tokens = [app + ':' + token, ...tokens];
//     }else
//     {
//       tokens = [app + ':' + token];
//     }

//     const fff = JSON.stringify(tokens);
//     this.cookie.set('Tokens',fff.toString() , 100, '/', '.gacad.pl');
//      //this.cookie.set('test_cookie', data.access_token, 100, '/', '.gacad.pl');
//  }

}