import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr'

import { User } from '../../shared/user.model';

import { UserService } from '../../shared/user.service';

import { TranslateService } from '@ngx-translate/core';



@Component({

  selector: 'app-sign-up',

  templateUrl: './sign-up.component.html',

  styleUrls: ['./sign-up.component.css']

})

export class SignUpComponent implements OnInit {

  user: User;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";


  constructor(private userService: UserService, private toastr: ToastrService, public translate: TranslateService) { }


  ngOnInit() {

    this.resetForm();

  }

// Password
// At least 8 characters in length
// Lowercase letters
// Uppercase letters
// Numbers Special characters

  resetForm(form?: NgForm) {

    if (form != null)

      form.reset();

    this.user = {

      first_name: '',

      last_name: '',

      email: '',

      phone_number: '',

      address: '',

      city: '',

      postal_code: '',

      country: '',

      password: '',

    }

  }

  OnSubmit(form: NgForm) {

    this.userService.registerUser(form.value)

      .subscribe((data: any) => {

        if (data.Succeeded == true) {

          this.resetForm(form);

          this.toastr.success(this.GetTranslationForSuccessRegistration());

        }

        else

          this.toastr.error(this.GetTranslationForErrorRegistration(data.Errors[0]));

      });

  }

  GetTranslationForErrorRegistration(error : string){

    var languageUsed = this.getSavedLanguage();

    // CODEFAILUREREGISTRATIONERROR1
    if(languageUsed == "pl" && error == 'Email is required')
    {
      return "Adres email jest wymagany";
    }

    // CODEFAILUREREGISTRATIONERROR2
    if(languageUsed == "pl" && error == 'Email is required | Password must have a minimum of eight characters, lowercase, uppercase letters, at least one special character and one number')
    {
      return "Adres email jest wymagany | Haslo mmusi posiadac 8 znakow, duze, male litery, jedna cyfre, znak specjalny";
    }

    // CODEFAILUREREGISTRATIONERROR3
    if(languageUsed == "pl" && error == 'Password must have a minimum of eight characters, lowercase, uppercase letters, at least one special character and one number')
    {
      return "Haslo mmusi posiadac 8 znakow, duze, male litery, jedna cyfre, znak specjalny";
    }

    if(languageUsed == "pl" && error.startsWith(' Unable'))
    {
      return " Nie mozna dodac uzytkownika poniewaz  - adres email " + this.user.email + " jest juz zarejestrowany.";
    }

    return error;
  }

 
  GetTranslationForSuccessRegistration(){
 // CODESUCCESFULREGISTRATION
    var languageUsed = this.getSavedLanguage();

    if(languageUsed == "en")
    {
      return "User registration successful";
    }

    if(languageUsed == "pl")
    {
      return "Uzytkownik zostal zarejestrowany";
    }

  }

  public getSavedLanguage(): string
  {
    const browserLang = this.translate.getBrowserLang();
    return browserLang.match(/en|pl/) ? browserLang : 'pl';
  }

}