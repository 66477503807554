import { Routes } from '@angular/router'

import { HomeComponent } from './home/home.component';

import { UserComponent } from './user/user.component';

import { SignUpComponent } from './user/sign-up/sign-up.component';

import { SignInComponent } from './user/sign-in/sign-in.component';

import { AuthGuard } from './auth/auth.guard';

import { LicenseComponent } from './license/license.component';

import { ApplicationComponent } from './application/application.component';

import { UsersComponent } from './users/users.component';

import { CustomerComponent } from './customer/customer.component';

import { UserDisplaySelfComponent } from './user-display-self/user-display-self.component';

import { CustomerDispalaySelfComponent } from './customer-dispalay-self/customer-dispalay-self.component';

import { LicenseBasicViewComponent } from './license-basic-view/license-basic-view.component';

import { ManageLicenseComponent } from './manage-license/manage-license.component';

import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';

import { ProvideEmailForPasswordChangeComponent } from './user/provide-email-for-password-change/provide-email-for-password-change.component';

import { ConfirmationEmailRequestPassChangeComponent } from './user/confirmation-email-request-pass-change/confirmation-email-request-pass-change.component';

import { ResetPasswordComponent } from './user/reset-password/reset-password.component';

import { ResetPasswordFormComponent } from './user/reset-password-form/reset-password-form.component';

import { InvalidResetPasswordCodeComponent } from './user/invalid-reset-password-code/invalid-reset-password-code.component';

import { SuccessPasswordResetComponent } from './user/success-password-reset/success-password-reset.component';

export const appRoutes: Routes = [

    {  path: 'home', component: HomeComponent,canActivate:[AuthGuard]},

    {  path: 'license', component: LicenseComponent,canActivate:[AuthGuard]},

    {  path: 'application', component: ApplicationComponent,canActivate:[AuthGuard]},

    {  path: 'customer', component: CustomerComponent,canActivate:[AuthGuard]},

    {  path: 'users', component: UsersComponent,canActivate:[AuthGuard]},

    {  path: 'profile', component: UserDisplaySelfComponent,canActivate:[AuthGuard]},

    {  path: 'mycustomerprofile', component: CustomerDispalaySelfComponent,canActivate:[AuthGuard]},

    {  path: 'licensebasic', component: LicenseBasicViewComponent,canActivate:[AuthGuard]},

    {  path: 'licensepermissions', component: ManageLicenseComponent,canActivate:[AuthGuard]},

    {

        path: 'signup', component: UserComponent,

        children: [{ path: '', component: SignUpComponent }]

    },

    {

        path: 'login', component: UserComponent,

        children: [{ path: '', component: SignInComponent }]

    },

    {

        path: 'forgotPassword', component: ForgotPasswordComponent,

        children: [{ path: '', component: ProvideEmailForPasswordChangeComponent }]

    },

    {

        path: 'confirmPassword', component: ForgotPasswordComponent,

        children: [{ path: '', component: ConfirmationEmailRequestPassChangeComponent }]

    },
    
    {

        path: 'reset-password', component: ResetPasswordComponent,
        
        children: [{ path: '', component: ResetPasswordFormComponent }]
    },

    {
        path: 'invalid-password-reset-code', component: ResetPasswordComponent,
        
        children: [{ path: '', component: InvalidResetPasswordCodeComponent }]
    },

    {
        path: 'success-password-reset', component: ResetPasswordComponent,
        
        children: [{ path: '', component: SuccessPasswordResetComponent }]
    },

    { path : '', redirectTo:'/login', pathMatch : 'full'}

    

];