import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { UserService } from '../shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const itemIndex = (item: any, data: any[]): number => {
    for (let idx = 0; idx < data.length; idx++) {
        if (data[idx].license_permission_key === item.license_permission_key) {
            return idx;
        }
    }

    return -1;
};

const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

@Injectable({
    providedIn: 'root'
})
export class EditLicensePermissionService extends BehaviorSubject<any[]> {
    private data: any[] = [];
    errors: any;
    private createdItems: any[] = [];
    private deletedItems: any[] = [];

    constructor(private http: HttpClient, private userService: UserService,
         private toastr: ToastrService, public translate: TranslateService) {
        super([]);
    }

    public read() 
    {
        this.userService.getLicensePermissions().subscribe((data: any) => {   
            this.data = data;
            super.next(data);    
      });
    }

    public remove(item: any): void 
    {
        let index = itemIndex(item, this.data);
        this.data.splice(index, 1);

        index = itemIndex(item, this.createdItems);
        if (index >= 0) {
            this.createdItems.splice(index, 1);
        } else {
            this.deletedItems.push(item);
        }

        this.userService.deactivateLicensePermission(item.license_permission_key)
        .subscribe((data: any) => {
        });

        super.next(this.data);
    }

    public save(data: any, isNew?: boolean) 
    {
        this.userService.addLicensePermission(data)
        .subscribe(
            result => {
                // Handle result
                this.read();
                this.toastr.success(this.GetTranslationForSuccessLicensePermissionAdded());
            },
            error => {

            //     let validationErrorDictionary = error.error.ModelState;
            //     for (var fieldName in validationErrorDictionary) {
            //         if (validationErrorDictionary.hasOwnProperty(fieldName)) {
            //         this.errors = (validationErrorDictionary[fieldName][0]);
            //         }
            //     }

            //   this.toastr.error(this.errors);
            this.toastr.error(this.GetTranslationForFailureLicensePermissionAdded());

            }
        
      );

    }

    GetTranslationForSuccessLicensePermissionAdded()
    {
        // CODESUCCESLICENSEPERMISSIONADDED
            var languageUsed = this.getSavedLanguage();
           
            if(languageUsed == "en")
            {
              return "Successfully added";
            }
           
            if(languageUsed == "pl")
            {
              return "Dodany uzytkownik do licencji";
            }   
    }

    GetTranslationForFailureLicensePermissionAdded()
    {
        // CODEFAILURELICENSEPERMISSIONADDED
            var languageUsed = this.getSavedLanguage();
           
            if(languageUsed == "en")
            {
              return "Permission not added - error occured";
            }
           
            if(languageUsed == "pl")
            {
              return "Nastapil blad przy zapisywaniu";
            }   
    }

    public getSavedLanguage(): string
    {
      const browserLang = this.translate.getBrowserLang();
      return browserLang.match(/en|pl/) ? browserLang : 'pl';
    }
}