import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../shared/user.service';
import { User } from '../shared/user.model';
import { ChangePasswordBindingModel} from  '../user-display-self/ChangePasswordBindingModel';

@Component({
  selector: 'pm-user-display-self',
  templateUrl: './user-display-self.component.html',
  styleUrls: ['./user-display-self.component.css']
})
export class UserDisplaySelfComponent implements OnInit {

  public editDataItem: ChangePasswordBindingModel;
  public isNew: boolean;

  user: User;
  errors: any;
  constructor(private userService: UserService, private toastr: ToastrService, public translate: TranslateService) { }


  ngOnInit() {
    this.resetForm();
      this.userService.getLoggedInUser().subscribe((data: any) => {
        this.resetForm();
        this.user = {
  
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number,
          address: data.address,
          city: data.city,
          postal_code: data.postal_code,
          country: data.country,
          password: '',
        }
      });
  }

  resetForm(form?: NgForm) {

    if (form != null)

      form.reset();

    this.user = {
      first_name: 'NULL',
      last_name: 'NULL',
      email: 'NULL',
      phone_number: 'NULL',
      address: 'NULL',
      city: 'NULL',
      postal_code: 'NULL',
      country: 'NULL',
      password: 'NULL',
    }
  }

  OnSubmit(form: NgForm) 
  {
    this.userService.getMyProfile('email').subscribe((userdata: any) => {

       this.userService.updateUserProfile(form.value, userdata)
                 .subscribe((data: any) => {
                  this.toastr.success(this.GetTranslationForSuccessProfileUpdate());
        }, (err) => this.toastr.error(this.GetTranslationForErrorProfileUpdate()));
    });
  }

  GetTranslationForErrorProfileUpdate(): string {
     // CODEFAILUREPROFILEERROR
    var languageUsed = this.getSavedLanguage();

    if(languageUsed == "en")
    {
      return "Error saving data";
    }

    if(languageUsed == "pl")
    {
      return "Nastapil blad przy zapisywaniu zmian";
    }
  }

  GetTranslationForSuccessProfileUpdate(): string {
      // CODESUCCESFULPROFILEUPDATE
      var languageUsed = this.getSavedLanguage();

      if(languageUsed == "en")
      {
        return "Changes Saved";
      }
  
      if(languageUsed == "pl")
      {
        return "Zmiany zostaly zapisane";
      }
  }

  public getSavedLanguage(): string
  {
    const browserLang = this.translate.getBrowserLang();
    return browserLang.match(/en|pl/) ? browserLang : 'pl';
  }

  // Change Password Section

  public addHandler()
  {
    this.editDataItem = new ChangePasswordBindingModel();
    this.isNew = true;
}

public cancelHandler()
{
  this.editDataItem = undefined;
}

public saveHandler(changePasswordBindingModel: ChangePasswordBindingModel) 
{
  this.save(changePasswordBindingModel, this.isNew);
  this.editDataItem = undefined;
}

private save(data: any, isNew?: boolean) 
{
  this.errors = '';
    this.userService.changePasswordInternal(data)
    .subscribe(
        result => {
            // Handle result
            this.toastr.success(this.GetTranslationForSuccessPasswordChangeInternal());
        },
        error => {
            let validationErrorDictionary = error.error.ModelState;
            for (var fieldName in validationErrorDictionary) {
                if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                this.errors = (validationErrorDictionary[fieldName][0]);
                }
            }

            if(this.errors == undefined || this.errors == '')
            {
              this.toastr.error(this.GetTranslationForErrorPasswordChangeInternal(error.error.Message));
            }
            else
            {
              this.toastr.error(this.GetTranslationForErrorPasswordChangeInternal(this.errors));
            }
        }
  );
}

GetTranslationForErrorPasswordChangeInternal(error: any): string 
{
  // CODEERRORPASSWORDINTERNALUPDATE1 -2

  var languageUsed = this.getSavedLanguage();
  if(languageUsed == "pl" && error == "The new password and confirmation password do not match.")
  {
    return "Nowe haslo i powtorka nowego nowego hasla roznia sie od siebie";
  }
  if(languageUsed == "pl" && error == "Unable To Change Password- invalid password")
  {
    return "Nie mozna zmienic hasla - podane haslo jest nieprawidlowe";
  }
  else
  {
    return error;
  }
}

GetTranslationForSuccessPasswordChangeInternal(): string {
  // CODESUCCESFULPASSWORDINTERNALUPDATE
  var languageUsed = this.getSavedLanguage();

  if(languageUsed == "en")
  {
    return "Password Changed Successfully";
  }

  if(languageUsed == "pl")
  {
    return "Haslo Zmienione";
  }
}


}
