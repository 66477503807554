import { BrowserModule } from '@angular/platform-browser';

import { NgModule, LOCALE_ID } from '@angular/core';

import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule } from '@angular/router'

import { AppComponent } from './app.component';

import { UserService } from './shared/user.service';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpBackend, HttpClientJsonpModule } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';

import { UserComponent } from './user/user.component';

import { SignInComponent } from './user/sign-in/sign-in.component';

import { HomeComponent } from './home/home.component';

import { SignUpComponent } from './user/sign-up/sign-up.component';

import { appRoutes } from './routes';

import { AuthGuard } from './auth/auth.guard';

import { AuthInterceptor } from './auth/auth.interceptor';
import {MatMenuModule} from '@angular/material/menu';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { LicenseComponent } from './license/license.component';
import { ApplicationComponent } from './application/application.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LicenseKendoComponent } from './license-kendo/license-kendo.component'
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ApplicationTelerikAdmComponent } from './application-telerik-adm/application-telerik-adm.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { PopupModule } from '@progress/kendo-angular-popup';
import { PopupAnchorDirective } from './application-telerik-adm/popup.anchor-target.directive';
import { UsersKendoComponent } from './users-kendo/users-kendo.component';
import { UsersComponent } from './users/users.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LicenseTelerikAdmComponent } from './license-telerik-adm/license-telerik-adm.component';
import { LicenseTelerikBasicComponent } from './license-telerik-basic/license-telerik-basic.component';
import { CustomerComponent } from './customer/customer.component';
import { CustomerTelerikBasicComponent } from './customer-telerik-basic/customer-telerik-basic.component';
import { UserDisplaySelfComponent } from './user-display-self/user-display-self.component';
import { CustomerDispalaySelfComponent } from './customer-dispalay-self/customer-dispalay-self.component';
import { LicenseBasicViewComponent } from './license-basic-view/license-basic-view.component';
import { ManageLicenseComponent } from './manage-license/manage-license.component';
import { GridEditLicensePermissionFormComponent } from './manage-license/grid-edit-license-permission-form/grid-edit-license-permission-form.component';
import { EditLicensePermissionService } from './manage-license/editLicensePermissionService.service';
import { EditActiveActivationsService } from './manage-license/editActiveActivationsService.service';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';

import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/pl/all';
import { KendoDialogChangePasswordFormComponent } from './user-display-self/kendo-dialog-change-password-form/kendo-dialog-change-password-form.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { ProvideEmailForPasswordChangeComponent } from './user/provide-email-for-password-change/provide-email-for-password-change.component';
import { ConfirmationEmailRequestPassChangeComponent } from './user/confirmation-email-request-pass-change/confirmation-email-request-pass-change.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { ResetPasswordFormComponent } from './user/reset-password-form/reset-password-form.component';
import { InvalidResetPasswordCodeComponent } from './user/invalid-reset-password-code/invalid-reset-password-code.component';
import { SuccessPasswordResetComponent } from './user/success-password-reset/success-password-reset.component';
import { KendoGridViewActivationsComponent } from './manage-license/kendo-grid-view-activations/kendo-grid-view-activations.component';
import { CookieService } from 'ngx-cookie-service'

export function HttpLoaderFactory(httpBackend: HttpBackend) : TranslateHttpLoader {
 return new TranslateHttpLoader(new HttpClient(httpBackend));  
}


@NgModule({

  declarations: [

    AppComponent,

    SignUpComponent,

    UserComponent,

    SignInComponent,

    HomeComponent,

    MainNavComponent,

    LicenseComponent,

    ApplicationComponent,

    UnauthorizedComponent,

    LicenseKendoComponent,

    ApplicationTelerikAdmComponent,

    PopupAnchorDirective,

    UsersKendoComponent,

    UsersComponent,

    LicenseTelerikAdmComponent,

    LicenseTelerikBasicComponent,

    CustomerComponent,

    CustomerTelerikBasicComponent,

    UserDisplaySelfComponent,

    CustomerDispalaySelfComponent,

    LicenseBasicViewComponent,

    ManageLicenseComponent,

    GridEditLicensePermissionFormComponent,

    KendoDialogChangePasswordFormComponent,

    ForgotPasswordComponent,

    ProvideEmailForPasswordChangeComponent,

    ConfirmationEmailRequestPassChangeComponent,

    ResetPasswordComponent,

    ResetPasswordFormComponent,

    InvalidResetPasswordCodeComponent,

    SuccessPasswordResetComponent,

    KendoGridViewActivationsComponent
  ],

  imports: [

    BrowserModule,

    FormsModule,

    HttpClientModule,

    ToastrModule.forRoot(),

    BrowserAnimationsModule,

    RouterModule.forRoot(appRoutes),

    MatMenuModule,

    LayoutModule,

    MatToolbarModule,

    MatButtonModule,

    MatSidenavModule,

    MatIconModule,

    MatListModule,

    MatTableModule,

    MatPaginatorModule,

    MatSortModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),

    ButtonsModule,

    ReactiveFormsModule,

    GridModule,

    DropDownListModule,

    HttpClientJsonpModule,

    DialogsModule,

    PopupModule,

    InputsModule,

    DateInputsModule,

    IntlModule,

    DropDownsModule
  ],

   providers: [UserService,AuthGuard,EditLicensePermissionService, EditActiveActivationsService, CookieService,
    ,

    {

      provide : HTTP_INTERCEPTORS,

      useClass : AuthInterceptor,

      multi : true
    },
    { provide: LOCALE_ID, useValue: "en-US" },
  ],

  bootstrap: [AppComponent]

})

export class AppModule { }