import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from "@angular/common/http";

import { Observable } from "rxjs/Observable";

import 'rxjs/add/operator/do';

import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";



@Injectable()

export class AuthInterceptor implements HttpInterceptor {



    constructor(private router: Router, private cookie: CookieService) { }



    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.headers.get('No-Auth') == "True")

            return next.handle(req.clone());

        const current_token = this.getCurrentToken();
        // if (localStorage.getItem('userToken') != null) {
        if (current_token != null) {
            const clonedreq = req.clone({

                // headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('userToken'))
                headers: req.headers.set("Authorization", "Bearer " + current_token)

            });

            return next.handle(clonedreq)

                .do(

                succ => { },

                err => {

                    if (err.status === 401)

                        this.router.navigateByUrl('/login');

                }

                );

        }

        else {

            this.router.navigateByUrl('/login');

        }

    }

    getCurrentToken() : string
    {
    //   let app_tokens = [];
    //   let current_token;
    //   app_tokens = JSON.parse(this.cookie.get('Tokens').toString());
    //   app_tokens.forEach(function (value)
    //   {
    //     const app_type = value.substring(0, 4);
    //     if(app_type == 'acc:')
    //     {
    //       current_token = value.slice(4);
    //     }
    //   }); 
  
    //   return current_token;
      return this.cookie.get('mps_token');
    }

}