import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pm-success-password-reset',
  templateUrl: './success-password-reset.component.html',
  styleUrls: ['./success-password-reset.component.css']
})
export class SuccessPasswordResetComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit() {
  }

  OnSubmit(NewPassword,ConfirmPassword)
  {
      this.router.navigate(['/login']);
  }

}
